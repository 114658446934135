// import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //引入element语言包
const cn = {
  login: { // 登录页面
    dlym01: '欢迎登录',
    dlym02: '还没有账号？',
    dlym03: '立即注册',
    dlym04: '请输入账号',
    dlym05: '请输入密码',
    dlym06: '记住密码',
    dlym07: '忘记密码？',
    dlym08: '登录',
    dlym09: '登录中...',
    dlym10: '登录成功' // 未翻译
  },
  register: { // 注册页面
    zcym01: '注册',
    zcym02: '已有账号？',
    zcym03: '立即登录',
    zcym04: '请输入账号',
    zcym05: '请输入密码',
    zcym06: '请再次输入密码',
    zcym07: '请输入邀请码(选填)',
    zcym08: '注册',
    zcym09: '联系客服',
    zcym10: '注册中...', // 未翻译
    zcym11: '请输入6至20位小写字母, 大写字母和数字的组合', // 未翻译
    zcym12: '两次密码设置不一致。' // 未翻译
  },
  contact: { // 联系客服页面
    lxkf01: '网页客服',
    lxkf02: 'TG客服',
    lxkf03: '官方频道',
    lxkf04: '点击咨询',
    lxkf05: 'TG VIP客服'
  },
  bottomTab: { // 主模块
    zmk01: '首页',
    zmk02: '活动',
    zmk03: '代理',
    zmk04: '我的',
    zmk05: '请登录', // 未翻译
    zmk06: '推荐',
    zmk07: '菜单'
  },
  home: { // 首页
    sy01: '登录',
    sy02: '注册',
    sy03: '充值',
    sy04: '提现',
    sy05: '任务',
    sy06: '我的收入',
    sy07: '邀请好友',
    sy08: '投注记录',
    sy09: '热门游戏',
    sy10: '电子',
    sy11: '棋牌',
    sy12: '真人',
    sy13: '体育',
    sy14: '即开竞猜',
    sy15: '1分竞猜',
    sy37: '下次不再弹出', // 错别字
    sy16: 'PG电子',
    sy17: '开元棋牌',
    sy18: 'PP电子',
    sy19: 'CR皇冠',
    sy20: 'DB视讯',
    sy21: '皇冠体育',
    sy22: 'CQ9电子',
    sy23: 'CQ9棋牌',
    sy24: 'PT电子',
    sy25: 'AG真人',
    sy26: '开元捕鱼',
    sy27: '选择筛选项',
    sy28: '游戏搜索',
    sy29: '购买虚拟币',
    sy30: '推荐VPN',
    sy31: '火币网',
    sy32: '币安',
    sy33: '欧易',
    sy34: '中币',
    sy35: '游戏事业',
    sy36: '合法游戏牌照',
    sy38: '添加到主屏幕', // 未翻译
    sy39: '查看全部',
    sy40: '热门'
  },
  czym: { // 充值页面
    czym01: '充值',
    czym02: '账户充值',
    czym03: '已支持币种',
    czym04: 'USDT',
    czym05: '美金-汇旺',
    czym06: '佰易钱包',
    czym07: '您的专属存款钱包地址', // 您的专属存款钱包地址 未翻译
    czym08: '复制地址，转账完成自动上分', // 复制地址，转账完成自动上分 未翻译
    czym09: '复制成功',
    czym10: '最低存款额度',
    czym111: '提示：', // 新增
    czym11: '确保您用于交易的网络是TRON网络，采用的协议是TRC20协议，否则存款不会到账，平台概不负责。确保您的存款不低于当前限额，存款后将自动到账。',
    czym12: '选择支付方式（支持ABA美金支付，',
    czym121: '请联系客服>',
    czym13: '汇旺转账',
    czym14: '汇旺扫码',
    czym15: '账号名称',
    czym16: '靓号',
    czym17: '复制',
    czym18: '转账完成，联系客服上分',
    czym19: '请通过手机汇旺APP软件转账功能向以上靓号转账，支持汇旺美金及汇旺USDT转账',
    czym20: '美金充值金额1:1兑换成USDT，最小充值金额为$1', // 10
    czym21: '请输入充值金额',
    czym22: '可以选择充值金额',
    czym23: '确认',
    czym24: '充值金额',
    czym25: '此汇旺码有效时间为北京时间',
    czym26: '二维码有效期间为10分钟，请在失效前通过手机汇旺APP软件扫码完成此次充值',
    czym27: '返回',
    czym28: '美金充值金额1:1兑换成USDT，最小充值金额为$1', // ?
    czym29: '账号名称',
    czym30: '靓号',
    czym31: '复制',
    czym32: '转账完成，联系客服上分',
    czym33: '请通过手机佰易APP软件转账功能向以上靓号转账，支持佰易美金及佰易USDT转账', // 请通过手机佰易钱包APP扫码转账或者内部转账功能向以上靓号转账，支持美金及USDT转账。
    czym34: '美金充值金额1:1兑换成USDT，最小充值金额为$10',
    czym35: '充值成功' // 未翻译
  },
  txym: { // 提现页面
    txym01: '提现',
    txym02: '网页提现',
    txym03: 'TG提现',
    txym04: '账户提现',
    txym05: '已支持币种',
    txym06: 'USDT',
    txym07: '美金-汇旺',
    txym08: '佰易钱包',
    txym09: '您的钱包地址',
    txym10: '添加地址',
    txym11: '提现金额',
    txym12: '未完成流水',
    txym13: '最低提现金额',
    txym14: '全部提现',
    txym15: '余额',
    txym16: '提现密码',
    txym17: '设置提现密码',
    txym18: '最小提现金额为 10U，单笔提现收取手续费 0U',
    txym19: '未完成流水为0时方可进行提现操作，否则需完成相应的游戏流水',
    txym20: '提现',
    txym21: '您的汇旺名称',
    txym22: '请输入您的汇旺名称(如', // '请输入您的汇旺名称（如'
    txym23: '您的汇旺账号',
    txym24: '请输入您的汇旺账号(如', // '请输入您的汇旺账号（如'
    txym25: '提现金额',
    txym26: '未完成流水',
    txym27: '最低提现金额',
    txym28: '全部提现',
    txym29: '余额',
    txym30: '提现密码',
    txym31: '设置提现密码',
    txym32: '最小提现金额为 10U，单笔提现收取手续费 0U',
    txym33: '未完成流水为0时方可进行提现操作，否则需完成相应的游戏流水',
    txym34: '提现',
    txym35: '您的佰易名称',
    txym36: '请输入您的佰易名称(如', // '请输入您的佰易名称（如'
    txym37: '您的佰易账号',
    txym38: '请输入您的佰易账号(如', // '请输入您的佰易账号（如'
    txym39: '提现金额',
    txym40: '未完成流水',
    txym41: '最低提现金额',
    txym42: '全部提现',
    txym43: '余额',
    txym44: '提现密码',
    txym45: '设置提现密码',
    txym46: '最小提现金额为 10U，单笔提现收取手续费 0U',
    txym47: '未完成流水为0时方可进行提现操作，否则需完成相应的游戏流水',
    txym48: '提现',
    txym49: '账户提现',
    txym50: '已支持币种',
    txym51: '余额',
    txym52: '最小提现金额为 10U，单笔提现收取手续费 0U',
    txym53: '为保障提现资金的安全到账，请通过官方机器人进行提现操作',
    txym54: '提现(跳转至机器人)'
  },
  rwzx: { // 任务中心
    rwzx01: '任务中心',
    rwzx02: '报名参加',
    rwzx03: '领取',
    rwzx04: '不可领取'
  },
  wdsr: { // 我的收入
    wdsr01: '我的收入',
    wdsr02: '下级业绩',
    wdsr03: '可领返水',
    wdsr04: '领取',
    wdsr05: '领取成功',
    wdsr06: '没有可以领取的佣金',
    wdsr07: '本人投注额1%的返水',
    wdsr08: '总投注',
    wdsr09: '有效投注',
    wdsr10: '返水结算',
    wdsr11: '领取记录',
    wdsr12: '返水记录',
    wdsr13: '申请时间',
    wdsr14: '结算金额',
    wdsr15: '结算状态',
    wdsr16: '没有更多了',
    wdsr17: '昨日返水',
    wdsr18: '今日预估返水',
    wdsr19: '历史总返水',
    wdsr20: '可领佣金',
    wdsr21: '领取',
    wdsr22: '下级投注额0.4%的返佣',
    wdsr23: '下级人数',
    wdsr24: '今日下级新增人数',
    wdsr25: '佣金结算',
    wdsr26: '领取记录',
    wdsr27: '昨日佣金',
    wdsr28: '今日预估佣金',
    wdsr29: '历史总佣金',
    wdsr30: '代理业绩',
    wdsr31: '查看下级业绩',
    wdsr32: '佣金记录',
    wdsr33: '今日业绩',
    wdsr34: '佣金结算周期为日结，新加坡时间凌晨2点后结算昨天的佣金',
    wdsr35: '业绩查询',
    wdsr36: '游戏分类',
    wdsr37: '下级业绩',
    wdsr38: '业绩比例',
    wdsr39: '哈希竞猜',
    wdsr40: '电子游戏',
    wdsr41: '棋牌游戏',
    wdsr42: '真人视讯',
    wdsr43: '体育竞猜',
    wdsr44: '今天',
    wdsr45: '昨天',
    wdsr46: '名称',
    wdsr47: '个人流水',
    wdsr48: '飞机号'
  },
  tzjl: { // 投注记录
    tzjl01: '投注记录汇总',
    tzjl02: '总投注',
    tzjl03: '单',
    tzjl04: '输赢',
    tzjl05: '投注额',
    tzjl06: '有效投注',
    tzjl07: '投注记录',
    tzjl08: '哈希竞猜',
    tzjl09: '电子游戏',
    tzjl10: '棋牌游戏',
    tzjl11: '真人视讯',
    tzjl12: '体育竞猜',
    tzjl13: '投注详情',
    tzjl14: '总计',
    tzjl15: '有效投注',
    tzjl16: '输赢',
    tzjl17: '总计',
    tzjl18: '有效投注',
    tzjl19: '输赢',
    tzjl20: '注单编号',
    tzjl21: '参与游戏',
    tzjl22: '下注区域',
    tzjl23: '中奖情况',
    tzjl24: '下注金额',
    tzjl25: '返奖金额',
    tzjl26: '开奖期号',
    tzjl27: '钱包地址',
    tzjl28: '私钥哈希',
    tzjl29: '注单时间',
    tzjl30: '点击验证',
    tzjl31: '总计',
    tzjl32: '有效投注',
    tzjl33: '输赢',
    tzjl34: '注单编号',
    tzjl35: '参与游戏',
    tzjl36: '下注时间',
    tzjl37: '投注金额',
    tzjl38: '有效投注',
    tzjl39: '输赢情况',
    tzjl40: '是否结算',
    tzjl41: '开奖号码'
  },
  mywd: { // 我的
    wd01: '邀请好友',
    wd02: '我的收入',
    wd03: '投注记录',
    wd04: '安全中心',
    wd05: '充值',
    wd06: '提现',
    wd07: '中心钱包',
    wd08: '红包',
    wd09: '口令红包',
    wd10: '输赢报表',
    wd11: '充提记录',
    wd12: '交易记录',
    wd13: '代理合作',
    wd14: '购买货币',
    wd15: '推荐VPN',
    wd16: '退出登录',
    wd17: '任务中心'
  },
  aqzx: { // 安全中心
    aqzx01: '全面守护您的账号安全',
    aqzx02: '设置账号',
    aqzx03: '设置邮箱',
    aqzx04: '修改邮箱',
    aqzx05: '修改登录密码',
    aqzx06: '设置提现密码',
    aqzx07: '修改提现密码',
    aqzx08: '提币地址管理',
    aqzx09: '请输入账号',
    aqzx10: '请输入登录密码',
    aqzx11: '请再次输入确认密码',
    aqzx12: '获取验证码',
    aqzx13: '请输入TG验证码',
    aqzx14: '请输入邮箱',
    aqzx15: '请输入验证码',
    aqzx16: '请输入登录密码',
    aqzx17: '为了保障您的账号和资金安全，请绑定邮箱',
    aqzx18: '请输入新邮箱',
    aqzx19: '提交',
    aqzx20: '请输入原提现密码',
    aqzx21: '请输入新提现密码',
    aqzx22: '请确认提现密码',
    aqzx23: '提交',
    aqzx24: '忘记密码',
    aqzx25: '请输入原密码',
    aqzx26: '请输入新密码',
    aqzx27: '请再次输入确认密码',
    aqzx28: '提交',
    aqzx29: '忘记密码',
    aqzx30: '添加地址',
    aqzx31: '当前已绑定地址',
    aqzx32: '添加新的提现地址',
    aqzx33: '添加新地址',
    aqzx34: '选择协议',
    aqzx35: '添加地址',
    aqzx36: '请输入地址',
    aqzx37: '温馨提示：为了您的资金安全，请确保您添加的地址与所选协议一致，否则您将会丢失这笔资金。',
    aqzx38: '确定'
  },
  wdhb: { // 我的红包
    hb01: '当前钱包余额',
    hb02: '发红包',
    hb03: '总金额',
    hb04: '红包个数',
    hb05: '红包类型',
    hb06: '流水金额要求',
    hb07: '截止时间',
    hb08: '红包剩余金额',
    hb09: '领取记录',
    hb10: '红包链接',
    hb11: 'TG红包链接',
    hb12: '推送telegram红包消息',
    hb13: '备注',
    hb14: '发红包',
    hb15: '总金额',
    hb16: '红包个数',
    hb17: '红包类型',
    hb18: '流水倍数',
    hb19: '截止时间',
    hb20: '备注',
    hb21: '确认',
    hb22: '新手红包',
    hb23: '福利红包',
    hb24: '1倍',
    hb25: '2倍',
    hb26: '3倍',
    hb27: '4倍',
    hb28: '5倍',
    hb29: '1天后',
    hb30: '2天后',
    hb31: '3天后',
    hb32: '新手红包：所有新人可以领取；福利红包：所有人都可以领取'
  },
  hbkl: { // 红包口令
    hbkl01: '输口令，领红包',
    hbkl02: '领取红包'
  },
  sylb: { // 输赢列表
    sybb01: '报表统计',
    sybb02: '时间',
    sybb03: '笔数',
    sybb04: '有效投注',
    sybb05: '输赢',
    sybb06: '总笔数',
    sybb07: '总输赢',
    sybb08: '表格中输赢列数据为本人投注输赢和本人返水的合计'
  },
  ctjl: { // 充提记录
    ctjl01: '充提记录',
    ctjl02: '当前钱包余额',
    ctjl03: '订单编号',
    ctjl04: '发起方向',
    ctjl05: '充值货币',
    ctjl06: '网络协议',
    ctjl07: '手续费',
    ctjl08: '发起额度',
    ctjl09: '到账额度',
    ctjl10: '交易哈希',
    ctjl11: '发起时间',
    ctjl12: '提现货币'
  },
  jyjl: { // 交易记录
    jyjl01: '交易记录',
    jyjl02: '账变',
    jyjl03: '充值',
    jyjl04: '提现',
    jyjl05: '优惠'
  },
  gmhb: { // 购买货币
    gmhb01: '购买虚拟币',
    gmhb02: '推荐交易所',
    gmhb03: '下载地址',
    gmhb04: '教程',
    gmhb05: '查看教程'
  },
  tjvpn: { // 推荐vpn
    tjvpn01: '相关软件下载',
    tjvpn02: 'VPN软件推荐',
    tjvpn03: '天极加速VPN',
    tjvpn04: '旋风加速VPN',
    tjvpn05: '直达下载',
    tjvpn06: '去中心化钱包下载',
    tjvpn07: '除交易所外，也可通过去中心化钱包转账人人娱乐充值钱包地址',
    tjvpn08: '其他下载',
    tjvpn09: '官方机器人',
    tjvpn10: '机器人投注教程'
  },
  hxhash: { // 哈希
    hx01: '哈希综合',
    hx02: '1.95~9.7倍返现',
    hx03: 'Key Hash最后1位数字（忽略字母、小数点）作为开奖结果',
    hx04: '已开期号',
    hx05: '下注期号',
    hx06: '更多已开/未开期号',
    hx07: '当期投注',
    hx08: '投注记录',
    hx09: '游戏规则',
    hx10: '限红',
    hx11: '大',
    hx12: '小',
    hx13: '单',
    hx14: '双',
    hx15: '大单',
    hx16: '小双',
    hx17: '大双',
    hx18: '小单',
    hx19: '输入金额',
    hx20: '确认',
    hx21: '重置',
    hx22: '重复投注',
    hx23: '加倍投注',
    hx24: '局数',
    hx25: '表格上方为最新的数据，数字代表该列号码未出现次数，红色数字为当期开奖号码',
    hx26: '(英文的时候心增一条备注)',
    hx27: '号码走势图',
    hx28: '单双走势图',
    hx29: '大小走势图',
    hx30: '下注成功',
    hx31: '下注期号为：',
    hx32: '很遗憾，再接再厉',
    hx33: '本次开奖结果为',
    hx34: '开奖期号',
    hx35: '开奖明细',
    hx36: '开奖私钥哈希',
    hx37: '点击验证',
    hx38: '我知道了',
    hx39: '手动刮奖模式',
    hx40: '购买内容',
    hx41: '开奖结果',
    hx42: '购买金额',
    hx43: '中奖金额',
    hx44: '验证私钥',
    hx45: '如何验证私钥哈希视频教程',
    hx46: '播放',
    hx47: '开奖时间',
    hx48: '期号',
    hx49: '钱包地址',
    hx50: '私钥哈希',
    hx51: '开奖状态',
    hx52: '平台依托于去中心化的区块链网络提前随机生成未来24小时',
    hx521: '28800期开奖钱包地址',
    hx522: '数据(3秒一期)作为开奖源，开奖后公布钱包私钥，以便玩家进行验证，真正做到平台不可操控数据，所有记录透明有迹可查。',
    hx53: '点击复制钱包私钥哈希，打开任何去中心化的钱包，导入钱包私钥哈希，查看钱包地址是否与平台给的钱包地址一致，如果存在不一致的情况，或发现任何开奖数据问题，平台无条件奖励百万USDT！',
    hx54: '期号查询',
    hx55: '已开期号',
    hx56: '请输入期号/钱包地址',
    hx57: '如何验证',
    hx58: '开奖时间',
    hx59: '开奖期号',
    hx60: '钱包地址',
    hx61: '开奖私钥哈希',
    hx62: '未开期号',
    hx63: '点击开奖期号、钱包地址、开奖私钥哈希内容可复制',
    hx64: '总计',
    hx65: '单',
    hx66: '有效投注',
    hx67: '输赢',
    hx68: '注单编号',
    hx69: '参与游戏',
    hx70: '下注区域',
    hx71: '中奖情况',
    hx72: '下注金额',
    hx73: '返奖金额',
    hx74: '开奖期号',
    hx75: '钱包地址',
    hx76: '私钥哈希',
    hx77: '注单时间',
    hx78: '点击验证',
    hx79: '哈希单双',
    hx80: '哈希牛牛',
    hx81: '哈希大小',
    hx82: '哈希庄闲',
    hx83: '幸运哈希',
    hx84: '色子单双',
    hx85: '色子大小',
    hx86: '牛1',
    hx87: '牛2',
    hx88: '牛3',
    hx89: '牛4',
    hx90: '牛5',
    hx91: '牛6',
    hx92: '牛7',
    hx93: '牛8',
    hx94: '牛9',
    hx95: '牛牛',
    hx96: '牛闲',
    hx97: '和',
    hx98: '2倍返现',
    hx99: '1.95倍返现',
    hx100: 'Key Hash从右到左读取3位1到6的数字数字（可重复）为每期开奖号码'
  },
  yxgz: { // 游戏规则
    yxgz01: '开奖规则',
    yxgz02: '为了保证游戏的绝对公平、公正、不可篡改，无人可控，平台提前随机生成未来24小时',
    yxgz03: '数据(3秒一期)，系统间隔20个期号开奖，最后一个期号最为时间做为封盘时间，预定开奖钱包私钥哈希值（Key hash）作为开奖结果。',
    yxgz04: '28800期开奖钱包地址',
    yxgz05: '为了保证游戏的绝对公平、公正、不可篡改，无人可控，平台提前随机生成未来',
    yxgz06: '24小时28800期开奖钱包地址',
    yxgz07: '...，依据私钥哈希值从右往左读取3个（1-6）数字（可重复）为每期开奖号码，作为开奖结果。',
    yxgz08: '1.开奖钱包的私钥哈希值（Key hash）最后5位数字作为牌面，其中，前3位为庄家牌面，后3位位闲家牌面，如私钥哈希值（000***57abc），其牌面为“57abc”，庄家为“57a”，闲家为“abc”，字母为“10”，相加出的点个位数比较',
    yxgz09: '2.牌型算法：牛一~牛牛倍数对应1~10倍',
    yxgz10: '3.闲家牌牛牛、牛九赢时反奖抽10%手续费',
    yxgz11: '4.如当前闲家为：aba。10+10+10=30 “牛牛”',
    yxgz12: '5.如当前庄家为：57a。5+7+10=22 “牛二”',
    yxgz13: '6.如点数一致，均扣1%手续费退还本金',
    yxgz14: '1.开奖钱包的私钥哈希值（Key hash）最后5位数字作为牌面，如私钥哈希值（000***a3b78），其牌面为“a3b78”',
    yxgz15: '2.庄家牌面为前2位，闲家牌面为后2位。0为最小，9位最大，字母（abc）为0',
    yxgz16: '3.如当前庄家为：a，3。数值为3',
    yxgz17: '4.如当前闲家为：7，8。数值为5',
    yxgz18: '5.压注庄赢，比较点数后，庄赢则赔1.95倍',
    yxgz19: '6.压注闲赢，比较点数后，闲赢则赔1.95倍',
    yxgz20: '7.压注和赢，比较点数后，和赢则赔8倍',
    yxgz21: '8.押注庄或闲，开和，无手续费，退还本金，此局押注不计入流水',
    yxgz22: '1.开奖钱包的私钥哈希值（Key hash）最后2位数分别为数字或字母的组合为赢',
    yxgz23: '期号2023122500006的私钥哈希值为',
    yxgz24: '例如:',
    yxgz25: '数据(3秒一期)，为了确保会员下注的是未开奖期号，系统间隔1个期号开奖，因此，最快3秒开奖，最慢6秒开奖，开奖钱包私钥哈希（KEY hash）作为开奖结果。',
    yxgz26: '1.开奖钱包的私钥哈希值（Key hash）最后一位数字作为开奖结果（忽略字母）',
    yxgz27: '2.数字0、1、2、3、4 识别为押小',
    yxgz28: '3.数字5、6、7、8、9 识别为押大',
    yxgz29: '2.数字1、3、5、7、9 识别为单数',
    yxgz30: '3.数字0、2、4、6、8 识别为双数',
    yxgz31: '单双：三颗骰子开出之点数总和为双数，三颗骰子开出之点数总和为单数。请注意：若【第一颗、第二颗、第三颗】点数相同，通吃【单】、【双】各注。',
    yxgz32: '大小：三颗骰子开出之点数总和值3-10为小，点数总和值11-18为大。请注意：若【第一颗、第二颗、第三颗】点数相同，通吃【大】、【小】各注。',
    yxgz33: '依次从右往左读取3个（1-6）数字（可重复）开奖号码为',
    yxgz34: '最后一位数字为',
    yxgz35: '10.特码则是选择一个号码进行投注，所选号码和开奖号码一样为中奖',
    yxgz36: '4.数字1、3、5、7、9 识别为单数',
    yxgz37: '5.数字0、2、4、6、8 识别为双数',
    yxgz38: '6.数字0、2、4 识别为小双',
    yxgz39: '7.数字6、8 识别为大双',
    yxgz40: '8.数字5、7、9 识别为大单',
    yxgz41: '9.数字1、3 识别为小单',
    yxgz42: '开奖号码为',
    yxgz43: '最后2位为',
    yxgz44: '开奖结果为双',
    yxgz45: '开奖结果为小',
    yxgz46: '开奖结果为幸运',
    yxgz47: '开奖结果为大',
    yxgz48: '开奖结果为大、双、大双，特码为'
  }
  // ...zhLocale //这里引入element语言包目的是在切换语言的时候，element的组件里面的文字能跟随切换
}
// 导出
export default cn
