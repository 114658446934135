<template>
  <div id="load_all" class="loading" style="display:none">
    <div class="loader1">
      <img :src="j9_loading" alt="" style="height: 100%;width: 100%;">
    </div>
    <!-- <div class="loader">
      <div class="load-img">
        <div class="circle" />
        <img :src="j9_loading" alt="" class="logo">
      </div>
    </div> -->
  </div>
</template>
<script>
import j9_loading from '@/assets/portal/首页loading.webp'
export default {
  data() {
    return {
      j9_loading: j9_loading
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20002;
    background: rgba(0,0,0,.7);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    // height: 100%;
    // width: 100%;
    .loader1{
      height: 95px;
      width: 264px;
    }
    .loader {
        width: 260px;
        height: 260px;
        background: #262833;
        box-shadow: 0 8px 32px 0 rgb(33 33 55 / 69%);
        border-radius: 36px;
        transform: translateY(-50px) scale(.5);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        .load-img {
            width: 148px;
            height: 148px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
            .circle {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                // background: url(~@/assets/portal/j9-loading1.png) 0 0 no-repeat;
                background-size: 100% 100%;
                // animation: rotateAnimation 1s linear infinite;
            }
            .logo {
                width: 72px;
            }
        }
    }
}
</style>
