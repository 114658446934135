import axios from 'axios'
import qs from 'qs'
import { Toast, Dialog } from 'vant'
import store from '@/store'
import { getToken } from '@/utils/auth'
import router from '@/router'

// 创建一个axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // withCredentials: true,
  timeout: 60000
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // const load = document.getElementById('load_all')
    // if (load.style.display === 'none') {
    //   load.style.display = 'flex'
    // } else {
    // }
    if (store.getters.token || getToken()) {
      config.headers.Authorization = getToken()
      config.headers.visitorId = localStorage.getItem('visitorId')
      if (config.params) {
        config.params.suid = getToken() || store.getters.token
        config.params.language = localStorage.lang || 'cn'
      } else {
        config.params = {}
        config.params.suid = getToken() || store.getters.token
        config.params.language = localStorage.lang || 'cn'
      }
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    // const load = document.getElementById('load_all')
    // if (load.style.display === 'flex') {
    //   load.style.display = 'none'
    // } else {
    //   // load.style.display = 'none'
    // }
    const res = response.data
    // res.status = '8888'
    // 返回码不正确
    if (res.status != '1') {
      // 5001订单不存在
      // if (res.code !== 5001) {
      //   Toast.fail(res.msg)
      // }
      // 401未登陆
      if (res.code === 401) {
        Dialog.alert({
          title: '提示',
          message: '您还未登录，请登录'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            router.push({ name: 'Login' })
          })
        })
      }
      // 订单轮询
      if (res.code === 5002) {
        return Promise.reject(new Error(res.msg || 'Error'))
      }
      // Toast.fail(res.msg)
      if (res.status == '8888') {
        // const suid = getToken() || store.getters.token
        if (router.path !== '/upgradeSystem') {
          router.push({ path: '/upgradeSystem' })
        }
        // window.open(window.location.origin + '/#/otherGame?suid=' + suid, '_self')
        // Toast({
        //   message: '系统升级中',
        //   icon: 'warning-o'
        // })
        return res
      } else if (res.msg.includes('用户不存在')) {
        // const newWin = window.open()
        // newWin.document.body.innerHTML = '正在加载中......'
        // axios.get(process.env.VUE_APP_BASE_API + '/getBotinfo').then(res => {
        //   newWin.location.href = res.data.boturl
        //   return Promise.reject(new Error('用户不存在' || 'Error'))
        //   // 注意data属性是固定的用法，用于获取后台的实际数据
        //   // console.log(ret)
        //   // console.log(ret.data)
        // })
        // request({
        //   url: '/draw/sendDraw',
        //   method: 'get',
        //   params: data
        // })
        // router.push({ path: '/home' })
        Toast({
          message: '请登录',
          icon: 'warning-o'
        })
        store.dispatch('user/resetToken')
      } else {
        Toast({
          message: res.msg,
          icon: 'warning-o'
        })
        return Promise.reject(new Error(res.msg || 'Error'))
      }
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error)
    Toast.fail(error.message)
    return Promise.reject(error)
  }
)

/**
 * 使用 application/x-www-form-urlencoded format
 * @param {*} url
 * @param {*} postData
 * @returns
 */
service.formDataPost = function(url, postData) {
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(postData),
    url
  }
  return service(options)
}

export default service
