<!--
 * @Author: Xushijie 87086132@qq.com
 * @Date: 2024-01-06 12:11:56
 * @LastEditors: Xushijie 87086132@qq.com
 * @LastEditTime: 2024-04-18 02:02:25
 * @FilePath: \mall\src\components\Tabbar\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div class="tabbar">
      <div class="customer-service" @click="showService=true" />
      <van-tabbar v-model="active" :fixed="false" route>
        <!-- <van-tabbar-item to="/home" icon="wap-home">首页</van-tabbar-item>
        <van-tabbar-item :class="selected?'van-tabbar-item van-tabbar-item--active':'van-tabbar-item'" style="margin-right:60px" icon="point-gift" @click="goActivity">活动</van-tabbar-item>
        <van-tabbar-item to="/partner" icon="cluster">代理</van-tabbar-item>
        <van-tabbar-item icon="gem" @click="showTip">VIP</van-tabbar-item>
        <van-tabbar-item icon="manager" @click="goUser">我的</van-tabbar-item> -->
        <van-tabbar-item v-for="(item ,index) in menuList" :key="index" :class="item.url===$route.path?'van-tabbar-item--active':''" :style="index===1?'margin-right:50px':''" @click="goTo(item.url,index)">{{ item.name }}
          <template #icon="props">
            <svg-icon :icon-class="item.url===$route.path ? item.iconActive : item.iconInactive" :width="20" :height="20" />
            <!-- <img :src="props.active ? item.iconActive : item.iconInactive" /> -->
          </template>
        </van-tabbar-item>

      </van-tabbar>
    </div>
    <!-- <div class="tabbar--placeholder" style="width:100%;padding-bottom:80px" /> -->
    <!-- <div style="width:100%;padding-bottom:80px" /> -->
    <van-popup v-model="showService">
      <customer-service @close="showService=false" />
    </van-popup>
  </div>
</template>

<script>
import variables from '@/styles/variables.scss'
import CustomerService from '@/views/portal/modules/CustomerService.vue'
export default {
  components: {
    CustomerService
  },
  data() {
    return {
      showService: false,
      active: 0,
      menuList: [
        { url: '/oldhome', iconActive: '首页_s', iconInactive: '首页_n', name: this.$t('bottomTab.zmk01') },
        { url: '/activity', iconActive: '活动_s', iconInactive: '活动_n', name: this.$t('bottomTab.zmk02') },
        { url: '/agentNew', iconActive: '代理_s', iconInactive: '代理_n', name: this.$t('bottomTab.zmk03') },
        { url: '/user', iconActive: '我的_s', iconInactive: '我的_n', name: this.$t('bottomTab.zmk04') }
      ]
    }
  },
  computed: {
    variables() {
      return variables
    }
  },
  methods: {
    goTo(url, index) {
      if (index === 1 || index === 3) {
        this.$store.getters.token ? (this.$router.push({ path: url })) : this.$toast(this.$t('bottomTab.zmk05'))
      } else {
        this.$router.push({ path: url })
      }
    },
    // goUser() {
    //   this.store.getters.token ? (this.$router.push({ path: '/user' }), this.selected = true) : this.$toast('请登录')
    // },
    showTip() {
      this.$toast('敬请期待')
    }
  }
}
</script>

<style lang="scss" scoped>
.tabbar {
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  // border-top: 2px solid #f5f5f5;
}
</style>
<style lang="less">
.customer-service{
  margin-left: calc(50% - 75px);
  bottom:30px;
  width: 150px;
  height: 158px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed!important;
  z-index: 999;
  background-image: url('~@/assets/portal/图标_客服.png');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.tabbar {
  .van-tabbar {
    background: url('~@/assets/portal/bg_底部导航.png') repeat-y top;
    background-size: 100%;
    border: none;
    height:120px;
  }
  // .van-tabbar-item--active {
  //   color: rgb(234, 200, 142);
  //   background-color: rgb(28, 38, 65);
  // }
  [class*=van-hairline]::after {
    border: none;
  }
}
::-webkit-scrollbar{
  width: 10px;
  height: 10px;
  background: #013b8e;
}
// 滚动条
::-webkit-scrollbar {
  width: 6px;
  background-color: rgba(0, 0, 0, 0);
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: inherit;
  // background-color: rgba(144, 147, 153, 0.3);
  background-color: rgba(144, 147, 153, 0);
  -webkit-transition: 0.3s background-color;
  transition: 0.3s background-color;
}
</style>
