<template>
  <div v-show="isShow" class="back-top">
    <span v-scroll-to="'body'">
      <svg-icon class="icon" icon-class="icon_top" :width="16" :height="16" />
      <div>TOP</div>
    </span>
  </div>
</template>

<script>
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'

Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

export default {
  props: {
    offsetTop: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      timer: null,
      isShow: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(async() => {
        const scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
        this.isShow = scrollTop > this.offsetTop
        if (this.isShow) {
          await this.$nextTick()
          const width = document.getElementsByClassName('back-top')[0].clientWidth
          document.getElementsByClassName('back-top')[0].setAttribute('style', 'left:calc(50% - ' + width / 2 + 'px);')
        }
      }, 15)
    }
  }
}
</script>

<style lang="scss" scoped>
.back-top {
  position: fixed;
  height: 88px;
  width: 88px;
  // right: calc(50% - 44px);
  bottom: 172px;
  z-index: 999;
  // opacity: 0.9;
background: rgba($color:#222333, $alpha: 0.9);
border-radius: 8px;
text-align: center;
font-family: DIN-Medium;
font-size: 22px;
color: #FFFFFF;
font-weight: 500;
.icon{
  margin:6px auto;
}
}
</style>
